import { createApp } from 'vue';
import * as VueRouter from 'vue-router'

import App from './App.vue'
//import axios from 'axios';
import globalMixins from './globalMixins.js'; 
//*********************
import Home from './components/HomePage.vue'

import Faq from './components/FaqPage.vue'
import Articles from './components/ArticlesPage.vue'
import Article from './components/ArticlePage.vue'
import Page from './components/PagePage.vue'

import Contacts from './components/ContactsPage.vue'
import Application from './components/ApplicationPage.vue'

import Career      from './components/CareerPage.vue'
import Careers      from './components/CareersPage.vue'

//*********************
const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: Home ,
        meta: { title: 'الصفحة الرئيسية' }
    },
    {
        path: '/home',
        name: 'HomePage',
        component: Home ,
        meta: { title: 'الرئيسية' }
    },

    {
        path: '/faq',
        name: 'FaqPage',
        component: Faq ,
        meta: { title: 'الأسئلة الشائعة' }
    },
    {
        path: '/articles',
        name: 'ArticlesPage',
        component: Articles ,
        meta: { title: 'المقالات' }
    },
    {
        path: '/article/:id/:slug',
        name: 'ArticlePage',
        component: Article ,
        meta: { title: 'مقالة' }
    },
    {
        path: '/page/:id/:slug',
        name: 'PagePage',
        component: Page ,
        meta: { title: 'مقالة' }
    },
    {
        path: '/career/:id/:slug',
        name: 'CareerPage',
        component: Career ,
        meta: { title: 'إعلان' }
    },
    {
        path: '/careers/:id/:slug',
        name: 'CareersSectionPage',
        component: Careers ,
        meta: { title: 'الوظائف' }
    },
    {
        path: '/careers',
        name: 'CareersPage',
        component: Careers ,
        meta: { title: 'الوظائف' }
    },

    {
        path: '/contacts',
        name: 'ContactsPage',
        component: Contacts ,
        meta: { title: 'تواصل معنا' }
    },
    {
        path: '/application',
        name: 'ApplicationPage',
        component: Application ,
        meta: { title: 'نشر إعلان' }
    },


    {
        path: '/:pathMatch(.*)*',
        redirect: { name: 'HomePage' }
    }

];

//*********************
const router = VueRouter.createRouter({
    history : VueRouter.createWebHistory('/'),
    routes,
})

router.beforeEach((to, from, next) => {
    document.title = 'عكاظ وظائف | ' + to.meta.title || 'عكاظ وظائف';
    next();
});


const app = createApp(App)
app.config.productionTip = false;
app.mixin(globalMixins); 
app.use(router)
//app.config.globalProperties.$http = axios; 
app.mount('#app');