<template>
    <header data-anim="fade" data-add-bg="" class="header -type-4 -shadow bg-white js-header">
      <div class="header__container border-bottom-light py-10">
        <div class="row justify-between items-center">
          <div class="col-auto">
            <div class="header-left d-flex items-center">
              <div class="header__logo pr-30 xl:pr-20 md:pr-0">
                <router-link to="/" data-barba class="lh-1">
                  <small class="d-block">وظائف</small>
                  <img src="assets/images/Logo_Okaz.svg" alt="logo" width="100" class="d-block" />
                </router-link>
              </div>
  
              <div 
                class="header__explore px-30 xl:px-20 -before-border -after-border xl:d-none"
              >
                <a href="#" class="d-flex items-center" data-el-toggle=".js-explore-toggle">
                  <i class="icon icon-explore mr-15"></i>
                  الأقسام
                </a>
  
                <div class="explore-content py-25 rounded-8 bg-white toggle-element js-explore-toggle">
                  
                  <div 
                    class="explore__item"
                    v-for="(section, index) in SQLSection" :key="index"
                  >
                    <router-link :to="'/careers/' + section.id + '/' + section.slug" class="text-dark-1">
                      {{ section.name }}
                    </router-link>
                  </div>
                  
                </div>
              </div>
  
              <div class="header-menu js-mobile-menu-toggle pl-30 xl:pl-20">
                <div class="header-menu__content">
                    <div class="mobile-bg js-mobile-bg"></div>

                    <div class="d-none xl:d-flex items-center px-20 py-10 ">
                        <a href="application.html" class="text-dark-1">نشر إعلان</a>
                    </div>

                    <div class="menu js-navList">
                        <ul class="menu__nav -is-active">
                            <li>
                              <router-link to="/">
                                الرئيسية
                              </router-link>
                            </li>

                            <li>
                              <router-link to="/careers">
                                أحدث الوظائف
                              </router-link>
                            </li>

                            <li>
                              <router-link to="/articles">
                                المقالات
                              </router-link>
                            </li>

                            <li 
                              class="explore__item"
                              v-for="(page, index) in SQLPages" :key="index"
                            >
                              <router-link :to="'/page/' + page.id + '/' + page.slug" class="text-dark-1">
                                {{ page.title }}
                              </router-link>
                            </li>

                        </ul>
                    </div>

                    <div class="mobile-footer px-20 py-20 border-top-light js-mobile-footer">
                        <div class="mobile-footer__number">
                            <div class="fw-500 text-dark-1">
                                رقم التواصل :
                            </div>
                            <div class="text-17 fw-500 text-purple-1"><a href="#0" class="en d-inline-block"> (+966) 123456789 </a></div>
                        </div>

                        <div class="lh-2 mt-10">
                            <div class="fw-500 text-dark-1">
                                العنوان :
                            </div>
                            <div>
                                المملكة العربية السعودية جده حي الرحاب
                            </div>
                            <div>
                                <a href="https://maps.app.goo.gl/VrZuXrDo4GJQmKPK7" class="d-block border mt-2">
                                    <i class="fa fa-map-marker mx-2" aria-hidden="true"></i>
                                    اذهب للخارطة
                                </a>
                            </div>
                        </div>

                        <div class="mobile-socials mt-10">
                            <a href="#" class="d-flex items-center justify-center rounded-full size-40">
                                <i class="fa fa-facebook"></i>
                            </a>

                            <a href="#" class="d-flex items-center justify-center rounded-full size-40">
                                <i class="fa fa-twitter"></i>
                            </a>

                            <a href="#" class="d-flex items-center justify-center rounded-full size-40">
                                <i class="fa fa-instagram"></i>
                            </a>

                            <a href="#" class="d-flex items-center justify-center rounded-full size-40">
                                <i class="fa fa-linkedin"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="header-menu-close" data-el-toggle=".js-mobile-menu-toggle">
                    <div class="size-40 d-flex items-center justify-center rounded-full bg-white">
                        <div class="icon-close text-dark-1 text-16"></div>
                    </div>
                </div>

                <div class="header-menu-bg"></div>
            </div>
            </div>
          </div>
  
          <div class="col-auto">
            <div class="header-right d-flex items-center">
              <div class="header-right__icons text-white d-flex items-center">
                <div class="pr-20 sm:pr-15">
                  <!-- Header search content goes here -->
                </div>
  
                <div class="d-none xl:d-block pl-20 sm:pl-15">
                  <button class="text-dark-1 items-center" data-el-toggle=".js-mobile-menu-toggle">
                    <i class="text-11 icon icon-mobile-menu"></i>
                  </button>
                </div>
              </div>
  
              <div class="header-right__buttons d-flex items-center ml-10 xl:ml-4 lg:d-none">
                <router-link to="/application" class="button -sm -purple-1 text-white">
                  <i class="text-18 mx-1 icon fa fa-user-o"></i>
                  نشر إعلان
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </template>

<script>
import axios from 'axios';

export default {
  name: 'NavPage',
  props: {

  },
  data(){
    return {
        SQLSection  : [],
        SQLPages    : []
        //--------------------
    }
  },
  created(){
    this.getSection();
    this.getPages();
  },
  methods : {
    //--------------------------
    getSection() {
        axios.get(this.ApiLink + 'get_section')
            .then((response) => {
               this.SQLSection = response.data;
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    },
    //--------------------------
    getPages() {
        axios.get(this.ApiLink + 'get_pages/nav')
            .then((response) => {
               this.SQLPages = response.data;
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    },
    //--------------------------
  }
}
</script>