<template>

  <section class="masthead -type-3 bg-light-6 js-mouse-move-container">
      <div class="container">

          <div class="row y-gap-30 items-center justify-center">
              <div class="col-xl-7 col-lg-11 relative z-5">
                  <div class="masthead__content pl-32 lg:pl-0">

                      <h2 data-anim-childxx="slide-up delay-1 ">
                        <span class="text-purple-1">
                          ابنِ مستقبلك المهني
                        </span>
                      </h2>
                      <h1 data-anim-childxx="slide-up delay-1 " class="masthead__title">
                          اكتشف الوظائف التي تنتظرك
                      </h1>
                  </div>
              </div>

              <div data-anim-childxx="slide-up delay-5" class="col-xl-5 col-lg-7 relative z-2">
                  <div class="masthead-image">
                      <div class="masthead-image__img1">
                          <div class="masthead-image__shape xl:d-none">
                              <img src="assets/img/home-4/masthead/shape.svg" alt="image" />
                          </div>
                          <img data-move="20" class="js-mouse-move" src="assets/images/masthead.png" alt="image" />
                      </div>

                      <div class="masthead-image__el1">
                          <div data-move="40" class="lg:d-none img-el -w-250 px-20 py-20 d-flex items-center bg-white rounded-8 js-mouse-move">
                              <div class="size-50 d-flex justify-center items-center bg-red-2 rounded-full">
                                  <img src="assets/img/masthead/1.svg" alt="icon" />
                              </div>
                              <div class="ml-20">
                                  <div class="text-orange-1 text-16 fw-500 lh-1">33.000 +</div>
                                  <div class="mt-3"> وظيفة منشورة</div>
                              </div>
                          </div>
                      </div>

                      <div class="masthead-image__el2">
                          <div data-move="40" class="shadow-4 img-el -w-260 px-40 py-20 d-flex items-center bg-white rounded-8 js-mouse-move">
                              <div class="img-el__side">
                                  <div class="size-50 d-flex justify-center items-center bg-dark-1 rounded-full">
                                      <img src="assets/img/masthead/2.svg" alt="icon" />
                                  </div>
                              </div>
                              <div class="">
                                  <div class="text-purple-1 text-16 fw-500 lh-1">معتمدة</div>
                                  <div class="mt-3">وظائف حقيقية</div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <!--  ****************** -->
  <section 
    class="layout-pt-lg layout-pb-lg" 
    v-if="SQLSection && SQLSection.length > 0"
  >
    <div class="container">
        <div class="row justify-center text-center">
            <div class="col-auto">
                <div class="sectionTitle">
                    <h2 class="sectionTitle__title">
                      الأقسام الرئيسية
                    </h2>
                    <p class="sectionTitle__text">
                        استكشف التحديات المثيرة وابنِ مستقبلك المهني مع فرص العمل المتنوعة والمجزية
                    </p>
                </div>
            </div>
        </div>

        <div class="row y-gap-30 pt-60 lg:pt-50">

            <div 
              class="col-xl-3 col-lg-4 col-md-6"
              v-for="(section, index) in SQLSection" :key="index"
            >
                <div class="categoryCard -type-3">
                    <div class="categoryCard__icon bg-light-3 mr-20">
                        <img :src="section.poster" class="icon icon-announcement text-35" />
                    </div>
                    <div class="categoryCard__content">
                        <h4 class="categoryCard__title text-17 fw-500">
                          {{  section.name }}
                        </h4>
                        <div class="categoryCard__text text-13 lh-1 mt-5">{{  section.count }}+ وظيفة</div>
                    </div>
                </div>
            </div>

        </div>
    </div>
  </section>

  <!--  ****************** -->

  <!--  ****************** -->
  <section 
    class="layout-pt-lg layout-pb-lg"
    v-if="SQLArticles && SQLArticles.length > 0"
  >
      <div class="container">
          <div data-anim-childx="slide-left delay-1" class="row y-gap-20 justify-between items-center">
              <div class="col-lg-6">
                  <div class="sectionTitle">
                      <h2 class="sectionTitle__title">
                          أحدث الأخبار والمقالات
                      </h2>

                      <p class="sectionTitle__text">
                          تابع التحديثات اليومية واحصل على المعرفة الحديثة! كن على اطلاع بأحدث التقدمات في مجال التوظيف والتنمية المهنية
                      </p>
                  </div>
              </div>

              <div class="col-auto">

                <router-link to="/articles" class="button -icon -light-3 text-purple-1">
                  <i class="icon-arrow-top-right text-13 ml-10"></i>
                  جميع المقالات
                </router-link>
              </div>
          </div>

          <div class="row y-gap-30 pt-50">

              <div 
                class="col-lg-4 col-md-6"
                v-for="(articles, index) in SQLArticles" :key="index"
              >
              <router-link
                :to="'/article/' + articles.id + '/' + articles.slug"
                class="blogCard -type-1 rounded-8 border-light position-relative"
              >
                      <div class="blogCard__image">
                          <img :src="articles.poster" :alt="articles.title" />
                      </div>
                      <div class="blogCard__content px-4 pb-4">
                          <div class="blogCard__date">
                            {{  articles.date_created }}                            
                          </div>
                          <h4 class="blogCard__title mt-0">
                                {{  articles.title }}
                          </h4>
                      </div>
                    </router-link>
              </div>

            </div>
      </div>
  </section>
  <!--  ****************** -->
  <section 
    class="layout-pt-md layout-pb-lg"
    v-if="SQLPartners && SQLPartners.length > 0"
  >
      <div class="container">

        <div class="row y-gap-20 justify-between items-center">
            <div class="col-lg-8">
                <div class="sectionTitle">
                    <h2 class="sectionTitle__title">
                      شركاء النجاح
                    </h2>

                    <p class="sectionTitle__text">
                        استكشف شركاؤنا وانضم إلى مجتمع الشراكة لتحقيق التطور والتميز. اكتشف كيف يمكنك المساهمة في رحلتنا نحو الإبداع والتفوق.
                    </p>
                </div>
            </div>
        </div>
        <div class="overflow-hidden pt-50">
            <div class="row">

                <div 
                  class="col-lg-2 col-md-4 col-2"
                  v-for="(partner, index) in SQLPartners" :key="index"
                >
                    <div class="bg-white border-light rounded-8 -featureCard-hover-2 ">
                        <div 
                          class="py-10 px-10 text-center swiper-image"
                        >
                            <img  :src="partner.poster" :alt="partner.title" />
                            <small class="text-muted mt-2 d-block">
                                {{  partner.title  }}
                            </small>
                        </div>
                    </div>
                </div>

            </div>
        </div>


      </div>
  </section>
  <!--  ****************** -->
  <!--  ****************** -->
</template>



<script>
import axios from 'axios';

export default {
  name: 'HomePage',
  props: {

  },
  data(){
    return {
        SQLArticles : [],
        SQLPartners : [],
        SQLSection  : []
        //--------------------
    }
  },
  created(){
    this.getArticles();
    this.getPartners();
    this.getSection();
    
  },
  methods : {
    //--------------------------
    getArticles() {
        axios.get(this.ApiLink + 'get_articles/3')
            .then((response) => {
               this.SQLArticles = response.data;
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    },
    //--------------------------
    getPartners() {
        axios.get(this.ApiLink + 'get_partners')
            .then((response) => {
               this.SQLPartners = response.data;
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    },
    //--------------------------
    getSection() {
        axios.get(this.ApiLink + 'get_section')
            .then((response) => {
               this.SQLSection = response.data;
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    },
    //--------------------------
  }
}
</script>
