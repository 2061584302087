<template>
      <div class="py-80 my-80">

        <section class="page-header -type-1">
          <div class="container">
            <div class="page-header__content">
              <div class="row">
                <div class="col-auto">
                  <div >
                    <small>
                      اكتشف فرص العمل من خلال شراكتنا
                    </small>
                    <h1 class="page-header__title">
                      الوظائف الشاغرة
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class=" layout-pb-lg-x">
            <div  class="container">

                <div class="">
                    <div class="accordion__item">
                        <div class="row y-gap-20 items-center justify-between pb-30">
                            <div class="col-auto">
                                <div class="text-14 lh-12">اسعتراض 
                                  <span class="text-dark-1 fw-500">{{  SQLCareers.length  }}</span>
                                   مجموع الوظائف الظاهرة</div>
                            </div>

                            <div class="col-auto">
                                <div class="row y-gap-20 items-center justify-between pb-30">
                                    <div class="col-12">
                                        <div class="row x-gap-20 y-gap-20">

                                            <div class="col-auto">
                                                <label class="text-16 lh-1 fw-500 text-dark-1 mx-2">نوع التوظيف</label>
                                                <select v-model="recruitment" name="recruitment" @change="getCareers" class="-dropdown -dark-bg-dark-2 -dark-border-white-10 py-2 px-4">
                                                    <option value="all">الكل</option>
                                                    <option v-for="recruitment in SQLRecruitment" :key="recruitment.id" :value="recruitment.id">{{ recruitment.name }}</option>
                                                </select>
                                            </div>

                                            <div class="col-auto">
                                                <label class="text-16 lh-1 fw-500 text-dark-1 mx-2">المدينة</label>
                                                <select v-model="city" name="city" @change="getCareers" class="-dropdown -dark-bg-dark-2 -dark-border-white-10 py-2 px-4">
                                                    <option value="all">الكل</option>
                                                    <option v-for="city in SQLCity" :key="city.id" :value="city.id">{{ city.name }}</option>
                                                </select>
                                            </div>

                                            <div class="col-auto">
                                                <label class="text-16 lh-1 fw-500 text-dark-1 mx-2">القسم</label>
                                                <select v-model="section" name="section" @change="getCareers" class="-dropdown -dark-bg-dark-2 -dark-border-white-10 py-2 px-4">
                                                    <option value="all">الكل</option>
                                                    <option v-for="section in SQLSection" :key="section.id" :value="section.id">{{ section.name }}</option>
                                                </select>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div 
                  class="row y-gap-30 justify-center"
                  v-if="SQLCareers "
                >
                  <!-- -***************** -->
                    <div 
                      v-for="(career, index) in SQLCareers" :key="index"
                      class="col-lg-6 "
                    >
                      <router-link
                        :to="'/career/' + career.id + '/' + career.slug"
                        class="coursesCard -type-4 d-flex sm:d-block items-center px-10 py-10 border-light"
                      >
                            <div class="coursesCard__content pl-20 sm:pl-10 pr-10">

                                <div class="d-flex x-gap-15 justify-between items-center py-10">
                                    <div class="items-center">
                                      <div class="text-14 lh-1">
                                        <div class="icon-location d-inline-block"></div>
                                        {{ career.city_name  }}
                                      </div>
                                    </div>

                                    <div class="d-flex items-center">
                                        <div class="mr-8">
                                            <img src="assets/images/svg/course_2.svg" alt="icon" />
                                        </div>
                                        <div class="text-14 lh-1">
                                          {{ career.date_created  }}
                                        </div>
                                    </div>

                                </div>

                                <div class="text-17 lh-13 fw-500 text-dark-1 mt-10 mb-2">
                                  {{ career.title  }}
                                </div>
                                <small class="text-justify mt-2">
                                  <div class="text-14 ">
                                    {{ career.brief  }}
                                  </div>
                                </small>

                                <div class="d-flex justify-between items-center mt-10">
                                    <div class="d-flex items-center">
                                        <div class="size-30">
                                            <img src="assets/images/temp/1.jpg" class="rounded-full" alt="LO" />
                                        </div>
                                        <div class="text-light-1 ml-10 text-14 ">
                                          {{ career.company  }}
                                        </div>
                                    </div>
                                    <div class="d-flex items-center">

                                    </div>
                                </div>
                            </div>
                          </router-link>
                    </div>
                  <!-- -***************** -->
                </div>
                <div v-else>
                  <center>
                    <h4>
                      لم نجد اي من النتائج 
                    </h4>
                  </center>
                </div>
            </div>
        </section>

      </div>
<!-- *********** -->
</template>



<script>
import axios from 'axios';

export default {
  name: 'CareersPage',
  props: {

  },
  data(){
    return {
      SQLCareers : [],
      //--------------------
      SQLSection : [],
      SQLCity : [],
      SQLRecruitment : [],
      //--------------------
      section: 'all',
      recruitment: 'all',
      city: 'all',
    }
  },
  created(){
    this.getSection();
    this.getCity();
    this.getRecruitment();
    
    this.getData();
    this.getCareers();
  },
  methods : {
    getData() {
      if(this.$route.params.id){
        this.section = this.$route.params.id; 
      }
    },
    getSection() {
          axios.get(this.ApiLink + 'get_section')
              .then((response) => {
                this.SQLSection = response.data;
              })
              .catch((error) => {
                  console.error('Error fetching data:', error);
              });
      },
      getCity() {
          axios.get(this.ApiLink + 'get_city')
              .then((response) => {
                this.SQLCity = response.data;
              })
              .catch((error) => {
                  console.error('Error fetching data:', error);
              });
      },
      getRecruitment() {
          axios.get(this.ApiLink + 'get_recruitment')
              .then((response) => {
                this.SQLRecruitment = response.data;
              })
              .catch((error) => {
                  console.error('Error fetching data:', error);
              });
      },
      //---------------------
      getCareers() {
          axios.get(this.ApiLink + 'get_careers/28/' + this.section + '/'  + this.city + '/'  + this.recruitment )
              .then((response) => {
                this.SQLCareers = response.data;
              })
              .catch((error) => {
                  console.error('Error fetching data:', error);
              });
      },
      //---------------------
  }
}
</script>
