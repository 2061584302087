<template>
    <section class="layout-pt-lg layout-pb-lg ">
        <div class="container">
            <div class="row justify-center text-center">
                <div class="col-xl-9 col-lg-9 col-md-11">
                    <div class="sectionTitle">
                        <h2 class="sectionTitle__title">
                            الأسئلة الشائعة
                        </h2>

                        <p class="sectionTitle__text">
                            تصفح للعثور على إجابات لأكثر الاستفسارات تداولًا. هل لديك سؤال لم تجده هنا؟ لا تتردد في الاتصال بنا للمساعدة والإرشاد الإضافي
                        </p>
                    </div>

                    <div 
                      class="accordion -block text-left pt-60 lg:pt-40" 
                      v-if="SQLFaq && SQLFaq.length > 0"
                    >

                        <div 
                         class="accordion__item bg-light-4"
                         v-for="(faq, index) in SQLFaq" :key="index"
                        >
                            <div class="accordion__button">
                                <div class="accordion__icon">
                                    <div class="icon" data-feather="plus"></div>
                                    <div class="icon" data-feather="minus"></div>
                                </div>
                                <span class="text-17 fw-500 text-dark-1">
                                  {{ faq.title }}
                                </span>
                            </div>

                            <div class="accordion__content-x">
                                <div class="accordion__content__inner">
                                  {{ faq.content }}
                                </div>
                            </div>
                        </div>

                      </div>
                </div>
            </div>
        </div>
    </section>
</template>



<script>
import axios from 'axios'; 
export default {
  name: 'FaqPage',
  props: {

  },
  data(){
    return {
        SQLFaq : []
    }
  },
  created(){
    this.getData();
  },
  methods : {
    //--------------------------
    getData() {
        axios.get(this.ApiLink + 'get_faqs')
            .then((response) => {
               //console.log( response.data);
               this.SQLFaq = response.data;
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    },
  }
}
</script>
