<template>

  <div class="bn_mode">
    <a @click="toggleDarkMode">
      <i :class="{ 'fa fa-moon-o': !isDarkMode, 'fa fa-sun-o text-white': isDarkMode }" ></i> 
    </a>
  </div>


  <main 
    class="main-content"
    
  >
    <Nav />
    <div 
      class="content-wrapper js-content-wrapper layout-pt-md "
      :class="{ 'bg-white': !isDarkMode, 'dark-mode': isDarkMode }"
    >
      <RouterView />
      <Footer />
    </div>
  </main>
</template>

<script>
import Footer from './components/FooterPage.vue';
import Nav from './components/NavPage.vue';

export default {
  name: 'App',
  components: {
    Nav,
    Footer
  },
  data() {
    return {
      isDarkMode: false,
    };
  },
  methods : {
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
    },
  }

}
</script>
<style>
.bn_mode {
  position: fixed;
  left: -1px;
  top: 15%;
  z-index: 101;
}
.bn_mode > a{
  padding: 5px;
  border: 1px solid #ddd;
  cursor: pointer;
  display: block;
  overflow: hidden;
}
.bn_mode > a > i{
  font-size: x-large;
}
.dark-mode {
  background-color: #222222;
  color: #ffffff;
}

.dark-mode .sectionTitle__text {
  color: #ffffff; 
}
.dark-mode .sectionTitle__title {
  color: #ccc; 
}
.dark-mode .blogCard.-type-1 .blogCard__title {
  color: #ccc; 
}
.dark-mode .text-light-1 {
  color: #999; 
}
.dark-mode .bg-white {
  background-color: #222222; 
}
.dark-mode .bg-light-4{
  background-color: #333333; 
}

.dark-mode .bg-light-6 {
  background-color: #333333; 
}

</style>
