<template>
  <footer class="footer -type-1 bg-dark-1 -green-links">
    <div class="container">
      <!-- Footer Header -->
      <div class="footer-header py-4 mt-4">
        <div class="row y-gap-20 justify-between items-center">
          <div class="col-auto">
            <div class="footer-header__logo items-center">
              <div class="d-inline-block pt-2 mx-3">
                <h5 class="text-white d-block">
                  <img src="assets/images/Logo_OkazW.svg" alt="logo" width="100" />
                  وظائف
                </h5>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="d-flex x-gap-20 y-gap-20 items-center flex-wrap">
              <div>
                <div class="d-flex x-gap-15 text-white">
                  <router-link to="/faq">
                    الأسئلة الشائعة
                  </router-link>


                  <li 
                    v-for="(page, index) in SQLPages" :key="index"
                  >
                    <router-link :to="'/page/' + page.id + '/' + page.slug" >
                      {{ page.title }}
                    </router-link>
                  </li>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Footer Header -->

      <!-- Footer Content -->
      <div class="py-30 border-top-light-15">
        <div class="row justify-between items-center y-gap-20">
          <div class="col-auto">
            <div class="d-flex items-center h-100 text-white">
              <span>© 2024 عكاظ للصحافة والنشر. جميع الحقوق محفوظة.</span>
            </div>
          </div>

          <div class="col-auto">
            <div class="footer-header-socials">
              <div class="text-white d-inline-block">
                <!-- Replace href links with actual links -->
                <a href="tel:+966177366661" target="_blank" class="underline"><i class="fa fa-phone-square" aria-hidden="true"></i><span class="en d-inline-block">+966120000148</span></a>
                |
                <a href="https://maps.app.goo.gl/VrZuXrDo4GJQmKPK7" target="_blank" class="underline"><i class="fa fa-map-marker" aria-hidden="true"></i> HJ39+24 جده , حي الرحاب</a>
              </div>
              <div class="footer-header-socials__list d-inline-block">
                |
                <!-- Replace "#" with actual social media links -->
                <a href="#" class="mx-2"><i class="icon-facebook"></i></a>
                <a href="#" class="mx-2"><i class="icon-twitter"></i></a>
                <a href="#" class="mx-2"><i class="icon-instagram"></i></a>
                <a href="#" class="mx-2"><i class="icon-linkedin"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Footer Content -->

    </div>
  </footer>
</template>

<script>

import axios from 'axios';

export default {
  name: 'FooterPage',
  props: {

  },
  data(){
    return {
        SQLPages    : []
        //--------------------
    }
  },
  created(){
    this.getPages();
  },
  methods : {
    //--------------------------
    getPages() {
        axios.get(this.ApiLink + 'get_pages/footer')
            .then((response) => {
               this.SQLPages = response.data;
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    },
    //--------------------------
  }
}
</script>