<template>
    <section class="page-header -type-1">
        <div class="container">
            <div class="page-header__content">
                <div class="row justify-center text-center">
                    <div class="col-auto">
                        <div >
                            <h1 class="page-header__title">
                                الأخبار والمقالات
                            </h1>
                        </div>

                        <div >
                            <p class="page-header__text">
                                تابع معنا يوميًا لتحصل على أحدث المعلومات! استفد من المعارف الجديدة وحافظ على مواكبتك لأحدث التغيرات في عالم التوظيف والتطوير المهني.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section 
      class="pb-4"
      v-if="SQLArticles && SQLArticles.length > 0"
    >
        <div class="container">
            <div class="row y-gap-30 pt-60 blogPage">
                
              <div 
                class="col-lg-4 col-md-6"
                v-for="(articles, index) in SQLArticles" :key="index"
              >
              <router-link
                :to="'/article/' + articles.id + '/' + articles.slug"
                class="blogCard -type-1 rounded-8 border-light"
              >
                      <div class="blogCard__image">
                          <img :src="articles.poster" :alt="articles.title" />
                      </div>
                      <div class="blogCard__content px-4 pb-4">
                          <div class="blogCard__date">
                            {{  articles.date_created }}                            
                          </div>
                          <h4 class="blogCard__title mt-0">
                              <a href="page.html">
                                {{  articles.title }}
                              </a>
                          </h4>
                      </div>
                </router-link>
              </div>
            </div>
        </div>
    </section>

</template>



<script>
import axios from 'axios';

export default {
  name: 'ArticlesPage',
  props: {

  },
  data(){
    return {
      SQLArticles : []

    }
  },
  created(){
    this.getData();
  },
  methods : {
    //--------------------------
    getData() {
        axios.get(this.ApiLink + 'get_articles/0')
            .then((response) => {
               this.SQLArticles = response.data;
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    },
  }
}
</script>
