<template>
  <div class="py-80 my-80">
      <section class="page-header -type-5 bg-white">
          <div class="container">
              <div class="page-header__content pt-90 pb-">
                  <div class="row y-gap-30 relative">
                    <div class="col-xl-2"></div>
                    <!-- **-***************************- -->
                      <div class="col-xl-8 col-lg-8">
                        <div class="pb-4" >
                          <h1 class="text-40 ">
                              طلب نشر إعلان عن وظيفة
                          </h1>
                          <p>
                            فرصة عمل مثيرة تنتظركم: انضموا إلينا وشاركوا في بناء مستقبلنا المهني معًا!
                          </p>
                          <hr>
                        </div>
                        <div class="card card-item " >
                            <div class="card-body">

                              <form class="pt-4 contact-form" @submit.prevent="submitForm">
                                  <div class="row">
                                      <h5 class="mt-4 mb-2 pb-4">بيانات المعلن</h5>
                                      <div class="input-box mb-4 col-md-6">
                                          <!-- Company Name -->
                                          <label class="text-16 lh-1 fw-500 text-dark-1 mb-10 text-red">اسم الشركة</label>
                                          <div class="form-group">
                                              <input v-model="company" type="text" name="company" placeholder="" required autocomplete="name" />
                                          </div>
                                      </div>
                                      <!-- Mobile Number -->
                                      <div class="input-box mb-4 col-md-6">
                                          <label class="text-16 lh-1 fw-500 text-dark-1 mb-10 text-red">رقم الجوال *</label>
                                          <div class="form-group">
                                              <input v-model="mobile" maxlength="12" minlength="8" pattern="[0-9]+" type="text" name="tell" required autocomplete="phone" autofocus />
                                          </div>
                                      </div>
                                      <!-- Email Address -->
                                      <div class="input-box mb-4 col-md-6">
                                          <label class="text-16 lh-1 fw-500 text-dark-1 mb-10 text-red">البريد الإلكتروني *</label>
                                          <div class="form-group">
                                              <input v-model="email" type="email" name="email" required autocomplete="email" />
                                          </div>
                                      </div>

                                      <!-- Advertisement Data -->
                                      <h5 class="mt-4 mb-3 pb-4">بيانات الإعلان</h5>
                                      <!-- Advertisement Title -->
                                      <div class="input-box mb-4 col-md-8">
                                          <label class="text-16 lh-1 fw-500 text-dark-1 mb-10 text-red">العنوان</label>
                                          <div class="form-group">
                                              <input v-model="title" type="text" name="title" placeholder="" required autocomplete="name" />
                                          </div>
                                      </div>
                                      <!-- Recruitment Type -->
                                      <div class="input-box mb-4 col-md-4">
                                          <label class="text-16 lh-1 fw-500 text-dark-1 mb-10 text-red">نوع التوظيف</label>
                                          <select v-model="recruitment" name="recruitment" required>
                                              <option value="">اختر</option>
                                              <option v-for="recruitment in SQLRecruitment" :key="recruitment.id" :value="recruitment.id">{{ recruitment.name }}</option>
                                          </select>
                                      </div>
                                      <!-- City -->
                                      <div class="input-box mb-4 col-md-6">
                                          <label class="text-16 lh-1 fw-500 text-dark-1 mb-10 text-red">المدينة</label>
                                          <select v-model="city" name="city" required>
                                              <option value="">اختر</option>
                                              <option v-for="city in SQLCity" :key="city.id" :value="city.id">{{ city.name }}</option>
                                          </select>
                                      </div>
                                      <!-- Section -->
                                      <div class="input-box mb-4 col-md-6">
                                          <label class="text-16 lh-1 fw-500 text-dark-1 mb-10 text-red">القسم</label>
                                          <select v-model="section" name="section" required>
                                              <option value="">اختر</option>
                                              <option v-for="section in SQLSection" :key="section.id" :value="section.id">{{ section.name }}</option>
                                          </select>
                                      </div>
                                      <!-- Advertisement Content -->
                                      <div class="input-box mb-4 col-md-12">
                                          <label class="text-16 lh-1 fw-500 text-dark-1 mb-10 text-red">الإعلان</label>
                                          <div class="form-group">
                                              <textarea v-model="content" name="content" placeholder="" rows="8" required></textarea>
                                          </div>
                                      </div>

                                  </div>
                                  <!-- Checkbox and Submit Button -->
                                  <div class="btn-box">
                                      <div class="custom-control custom-checkbox mb-4 fs-15">
                                          <input type="checkbox" class="custom-control-input" id="agreeCheckbox" required />
                                          <label class="custom-control-label custom--control-label" for="agreeCheckbox">
                                              بالنقر هنا توافق على
                                              <a href="" target="_blank" class="text-color hover-underline">الأحكام والشروط</a>
                                              و
                                              <a href="" target="_blank" class="text-color hover-underline">شروط النزاهة</a>
                                          </label>
                                      </div>
                                      <div class="col-12">

                                        <div class="my-4  bg-dark-4 py-2 px-4 text-white"  v-if="alertDanger">
                                          <div v-html="alertDanger"></div>
                                        </div>

                                        <div v-if="isFinish === 1">
                                          <button type="submit" name="submit" id="submit" class="button -md -purple-1 text-white fw-500 ">
                                              إرسال
                                          </button>
                                        </div>
                                      </div>
                                  </div>
                                  <!-- end btn-box -->
                              </form>

                              
                              </div>
                            <!-- end card-body -->
                        </div>


                      </div>
                      <!-- **-***************************- -->
                      <div class="col-xl-2"></div>
                  </div>
              </div>
          </div>
      </section>
  </div>
  <!-- *********** -->
</template>



<script>
import axios from 'axios';

export default {
  name: 'ApplicationPage',
  props: {

  },
  data(){
    return {
      SQLSection : [],
      SQLCity : [],
      SQLRecruitment : [],
      //--------------------
      alertDanger : "",
      isFinish : 1,

      company: '',
      mobile: '',
      email: '',
      title: '',
      recruitment: '',
      city: '',
      section: '',
      content: ''
    }
  },
  created(){
    this.getSection();
    this.getCity();
    this.getRecruitment();

  },
  methods : {
      getSection() {
          axios.get(this.ApiLink + 'get_section')
              .then((response) => {
                this.SQLSection = response.data;
              })
              .catch((error) => {
                  console.error('Error fetching data:', error);
              });
      },
      getCity() {
          axios.get(this.ApiLink + 'get_city')
              .then((response) => {
                this.SQLCity = response.data;
              })
              .catch((error) => {
                  console.error('Error fetching data:', error);
              });
      },
      getRecruitment() {
          axios.get(this.ApiLink + 'get_recruitment')
              .then((response) => {
                this.SQLRecruitment = response.data;
              })
              .catch((error) => {
                  console.error('Error fetching data:', error);
              });
      },
      //----------------
      submitForm() {
            this.alertDanger = "";

            if (
                !this.company || this.company.length < 5 ||
                !this.title || this.title.length < 5 ||
                !this.content || this.content.length < 5 ||
                !this.isValidMobile(this.mobile) ||
                !this.isValidEmail(this.email) ||
                !this.recruitment ||
                !this.city ||
                !this.section
            ) {

                let errorMessage = "يرجى إصلاح الأخطاء التالية  :";
                if (!this.company || this.company.length < 5) errorMessage += ' <br /> -  اسم الشركة (الحد الأدنى لطول 5 أحرف)';
                if (!this.title || this.title.length < 5) errorMessage += ' <br /> -  العنوان (الحد الأدنى لطول 5 أحرف)';
                if (!this.content || this.content.length < 5) errorMessage += ' <br /> -  المحتوى (الحد الأدنى لطول 5 أحرف)';
                if (!this.isValidMobile(this.mobile)) errorMessage += ' <br /> -  رقم الجوال غير صالح';
                if (!this.isValidEmail(this.email)) errorMessage += ' <br /> -  البريد الإلكتروني غير صالح';
                if (!this.recruitment) errorMessage += ' <br /> -  نوع التوظيف غير محدد';
                if (!this.city) errorMessage += ' <br /> -  المدينة غير محددة';
                if (!this.section) errorMessage += ' <br /> -  القسم غير محدد';

                this.alertDanger = errorMessage;
            } else {
                this.isFinish = 0;
                const formData = {
                    company: this.company,
                    mobile: this.mobile,
                    email: this.email,
                    title: this.title,
                    recruitment: this.recruitment,
                    city: this.city,
                    section: this.section,
                    content: this.content
                };
                axios.post(this.ApiLink + 'advertisement_appliction', formData, {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': 'LiceXXjppjAUATXTxQ9TmeOY4fD95fDW'
                        }
                    })
                    .then((response) => {
                        //console.log('Form submitted successfully', response.data);
                        this.company = '';
                        this.mobile = '';
                        this.email = '';
                        this.title = '';
                        this.recruitment = '';
                        this.city = '';
                        this.section = '';
                        this.content = '';
                        this.alertDanger = "تم إضافة إعلانك بنجاح وسيتم مراجعته والتواصل معك , رقم إعلانك" & response.data.success;
                        this.isFinish = 1;

                    })
                    .catch(error => {
                        console.error('Error submitting form:', error);
                        this.isFinish = 1;
                    });
            }
        },
        isValidMobile(mobile) {
            const regex = /^(0|5)\d{8,10}$/;
            return regex.test(mobile);
        },
        isValidEmail(email) {
            const regex = /\S+@\S+\.\S+/;
            return regex.test(email);
        }
      //----------------
    }
}
</script>
