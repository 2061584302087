<template>
    <div 
    class="py-80 my-80"
    v-if="SQLPage"

    >
        <section class="page-header -type-1">
            <div class="container">
                <div class="page-header__content">
                    <div class="row justify-center text-center">
                        <div class="col-auto">
                            <div data-animx="slide-up delay-1">
                                <h1 class="page-header__title">
                                    {{  SQLPage.title }}
                                </h1>
                            </div>

                            <div data-animx="slide-up delay-2">
                                <p class="page-header__text">

                                </p>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </section>
        <section class="py-4">
            <div data-animx-wrap class="container">
                <div data-animx="slide-up delay-3" class="row justify-center">
                    <div class="col-xl-8 col-lg-9 col-md-11">
                      <div v-html="SQLPage.content"></div>
                    </div>
                </div>
            </div>
        </section>

        <div class="row justify-center pt-30">
            <div class="col-xl-8 col-lg-9 col-md-11">
                <hr />
                <div class="row y-gap-20 justify-between items-center">
                    <div class="col-auto">
                        <div class="d-flex items-center">
                            <div class="lh-1 text-dark-1 fw-500 mr-30">مشاركة :</div>

                            <div class="d-flex x-gap-15">
                                <a href="#">Fb</a>
                                <a href="#">Tw</a>
                                <a href="#">In</a>
                                <a href="#">Ln</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-auto">
                        <div class="row x-gap-10 y-gap-10"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>



<script>
import axios from 'axios';

export default {
  name: 'PagePage',
  props: {

  },
  data(){
    return {
        SQLPage : [],
        //--------------------
        pageID : 0,
        pageSlug : "",
    }
  },
  created(){
    this.pageID = this.$route.params.id; 
    this.pageSlug = this.$route.params.slug; 
    this.getData();
  },
  methods : {
    //--------------------------
    getData() {
        axios.get(this.ApiLink + 'page/' + this.pageID)
            .then((response) => {
                  this.SQLPage = response.data;
                  //-------------
                  if (this.SQLPage.length === 0) {
                      this.$router.push('/');
                  }
                  //-------------
                  if (this.pageSlug != this.SQLPage.slug) {
                      this.$router.push('/');
                  }

            })
            .catch((error) => {
                this.$router.push('/');
                console.error('Error fetching data:', error);
            });
    },
  }
}
</script>
