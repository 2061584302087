<template>
  <div>
    Hello
  </div>
</template>



<script>
//import axios from 'axios';

export default {
  name: 'ContactsPage',
  props: {

  },
  data(){
    return {
        hostLink : 'http://127.0.0.1:8000/',
        //--------------------
    }
  },
  created(){
    this.getData();
  },
  methods : {
    //--------------------------
    getData() {
        /*axios.get(this.hostLink + 'api/ece4724c41ae49a3b7e98e1b97c59d1a/branches')
            .then((response) => {
               //console.log( response.data);
               this.SQLBranches = response.data;
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });*/
    },
  }
}
</script>
